<template>
  <div class="jsmb-account-information">
    <h2 class="jsmb-title-detail has-text-text is-size-4">Mes annonces</h2>
    <p v-if="announcements.length === 0">Vous n'avez pas d'annonce.</p>
    <div v-for="(announcement, announcementIndex) in announcements" :key="announcement.announcement_title">
      <div class="header-annonce has-background-primary is-clearfix">
        <h2 class="has-text-weight-bold is-uppercase is-size-4 is-pulled-left noPlace">{{announcement.announcement_title}}</h2>
        <div class="flexbtns">
          <span class="tag is-warning statusAnnonce">{{announcement.announcement_status}}</span>
          <a class="button is-small is-white" @click="updatePhoto(announcementIndex)">Modifier les photos</a>
          <a class="button is-small is-white is-pulled-right" @click="addSpace(announcementIndex)">Ajouter une place</a>
        </div>
      </div>
      <div class="columns content-annonce" v-for="(space, spaceIndex) in announcement.spaces" :key="'space-'+spaceIndex">
        <div class="column is-6 detail-place is-clearfix">
          <!-- {{space.space_type}} / EMPLACEMENT {{space.location_type}} -->
          <h3 class="has-text-weight-bold is-uppercase is-pulled-left noPlace">{{space.space_title}}</h3>
          <span class="tag is-pulled-left">{{space.status}}</span>
          <span class="tag is-info is-pulled-right">{{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(space.price_per_day_ttc)}} / jour</span>
        </div>
        <div class="column is-6 detail-buttons">
          <div class="buttons is-right">
            <a class="button is-small is-info"
              @click="updateSpace(announcementIndex, spaceIndex)"
              :class="space.status_number === 2?'disabled':''"
              :disabled="space.status_number === 2">
              Modifier
            </a>
            <a class="button is-small is-danger"
              @click="removeSpace(announcementIndex, spaceIndex)"
              :class="space.status_number === 2?'disabled':''"
              :disabled="space.status_number === 2">
              Supprimer
            </a>
            <a class="button is-small is-link"
              @click="spaceFree(true, announcementIndex, spaceIndex)"
              v-if="space.status_number === 3"
              :class="space.status_number === 2?'disabled':''"
              :disabled="space.status_number === 2">
              Activer
            </a>
            <a class="button is-small is-link"
              @click="spaceFree(false, announcementIndex, spaceIndex)"
              v-else
              :class="space.status_number === 2?'disabled':''"
              :disabled="space.status_number === 2">
              Suspendre
            </a>
          </div>
        </div>
      </div>
      <div class="footer-annonce">
        Publié le {{announcement.updated_at}}, à {{announcement.city}} - {{announcement.zip_code}}
      </div>
    </div>

    <b-modal :active.sync="isModalPlace">
      <div style="background:#fff;padding:2rem;">
        <StepTwo :hideForModal="false" :placeAction="modalPlaceAction" :place="modalPlace" @add-update-space="addUpdateSpace"/>
      </div>
    </b-modal>

    <b-modal :active.sync="isModalPhoto">
      <div style="background:#fff;padding:2rem;">
        <StepThee :hideForModal="false" :announcementId="announcement_id" :pictures="modalPhoto" @add-update-picture="addUpdatePhoto"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { GET_ANNOUNCEMENT_ACCOUNT, SET_SPACE_FREE, REMOVE_SPACE } from '@/store/modules/database/actions/database.actions'
import StepTwo from '@/components/wizard/wizard-sections/StepTwo.vue'
import StepThee from '@/components/wizard/wizard-sections/StepThree.vue'

export default {
  name: 'Place',
  components: {
    StepTwo,
    StepThee,
  },
  data () {
    let noImage = require('./../../../assets/no-image.png')
    return {
      modalPlaceAction: '',
      modalPlace: [],
      isModalPlace: false,
      modalPhoto: {
        file_1: {
          id: null,
          replace: false,
          remove: false,
          btnRemove: false,
          object: null,
          view: noImage,
        },
        file_2: {
          id: null,
          replace: false,
          remove: false,
          btnRemove: false,
          object: null,
          view: noImage,
        },
        file_3: {
          id: null,
          replace: false,
          remove: false,
          btnRemove: false,
          object: null,
          view: noImage,
        },
        file_4: {
          id: null,
          replace: false,
          remove: false,
          btnRemove: false,
          object: null,
          view: noImage,
        },
      },
      isModalPhoto: false,
      announcement_id: '',
      announcements: [],
    }
  },
  methods: {
    spaceFree(status, announcementIndex, spaceIndex) {
      // Set Space Free
      this.$store.dispatch(SET_SPACE_FREE,
        { spaceId: this.announcements[announcementIndex]['spaces'][spaceIndex]['space_id'], free: status }
      ).then(
        resp => {
          this.announcements[announcementIndex]['spaces'][spaceIndex]['status'] = resp.status
          this.announcements[announcementIndex]['spaces'][spaceIndex]['status_number'] = resp.status_number
        }
      )
    },
    removeSpace(announcementIndex, spaceIndex) {
      if(confirm("Voulez-vous vraiment supprimer la place "+this.announcements[announcementIndex]['spaces'][spaceIndex]['space_title']+" ?")){
        // Remove Space
        this.$store.dispatch(REMOVE_SPACE,
          { spaceId: this.announcements[announcementIndex]['spaces'][spaceIndex]['space_id'] }
        ).then(
          resp => {
            if (resp.announcement) {
              this.$delete(this.announcements, announcementIndex)
            }else{
              this.$delete(this.announcements[announcementIndex]['spaces'], spaceIndex)
            }
          }
        )
      }
    },
    addSpace(announcementIndex) {
      this.isModalPlace = true
      this.modalPlaceAction = 'add'
      this.modalPlace = {announcement_id: this.announcements[announcementIndex]['announcement_id']}
    },
    updateSpace(announcementIndex, spaceIndex) {
      this.isModalPlace = true
      this.modalPlaceAction = 'update'
      this.modalPlace = this.announcements[announcementIndex]['spaces'][spaceIndex]
    },
    addUpdateSpace(space) {
      this.isModalPlace = false
      this.modalPlaceAction = ''
      this.modalPlace = []

      this.announcements[space.announcement_id]['spaces'][space.space_id] = space
    },
    updatePhoto(announcementIndex) {
      this.isModalPhoto = true
      this.announcement_id = announcementIndex
      for (let index = 0; index < Object.entries(this.announcements[announcementIndex].pictures).length; index++) {
        this.modalPhoto['file_'+(index+1)].id = Object.entries(this.announcements[announcementIndex].pictures)[index][0]
        this.modalPhoto['file_'+(index+1)].view = Object.entries(this.announcements[announcementIndex].pictures)[index][1]
        this.modalPhoto['file_'+(index+1)].btnRemove = true
      }
    },
    addUpdatePhoto() {
      this.isModalPhoto = false
      location.reload()
    },
  },
  created() {
    // Get Announcement Account
    this.$store.dispatch(GET_ANNOUNCEMENT_ACCOUNT).then(
      resp => {
        this.announcements = resp
      }
    )
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
  .header-annonce,
  .footer-annonce {
    padding:0.8em;
  }
  .footer-annonce {
    background:#e8e8e8;
  }
  .header-annonce h2 {
    color: #ffffff
  }
  .header-annonce .button {
    margin: 0;
    margin-left: 1rem;
    padding:0.4rem 0.8rem
  }
  .footer-annonce {
    margin-bottom: 1rem;
    font-size: 12px;
  }
  .content-annonce {
    padding: 0.8em;
  }
  .content-annonce:nth-child(even) {
    background:rgba(255,255,255,0.8)
  }
  .content-annonce:nth-child(odd) {
    background:rgba(255,255,255,0.4)
  }
  .content-annonce .button {
    margin: 0;
  }
  .space-between {
    justify-content: space-between;
  }
  .detail-place {
    padding-right: 1rem
  }
  .noPlace {
    margin-right: 1em;
  }
  .statusAnnonce {
    margin-top: 0.2em;
  }
  /* A SUPPRIMER ? */
  .message-looper {
    margin-bottom: 8rem;
  }
  .jsmb-stockage {
    background-color: #ffffff;
    padding:1rem;
    margin-bottom:1rem;
  }
  .jsmb-place-order {
    color:#0ec0b6;
    text-transform: uppercase;
    font-weight: bold;
    font-weight: 1.2rem;
  }
  .jsmb-stockage-prix p{
    font-weight: bold;
    text-transform: uppercase;
  }
  strong {
    display: block;
    text-transform: uppercase;
  }
  strong:last-of-type {
    margin-bottom: 1rem;
  }
  .button {
    margin-bottom: 1rem;
    margin-right: 1.5rem;
  }
  @media screen and (max-width:798px){
    .message-looper {
      margin-bottom: 2rem;
    }
    .is-horizontal-center {
      margin:0rem auto 1rem
    }
    .jsmb-stockage-prix,
    .jsmb-message-text {
      margin-top:1rem;
    } 
  }
  .disabled {
    pointer-events: none;
  }
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: portrait) {
    .noPlace {
      width: 100%;
    }
    .flexbtns {
      flex-direction: column;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      width: 100%;
    }
    .statusAnnonce {
      margin-bottom: 1rem;
      width:max-content;
      margin-left: auto;
    }
    .header-annonce .button {
      margin-left: 0;
      margin-bottom: 1rem;
    }
    .buttons.is-right {
      margin: 1rem auto;
      justify-content: space-between
    }

  }
  @media screen and (min-width:320px) and (max-width:1049px) and (orientation: landscape) {
    .noPlace {
      width: 100%;
    }
    .flexbtns {
      flex-direction: column;
      display: inline-flex;
      align-content: center;
      justify-content: center;
      width: 100%;
    }
    .statusAnnonce {
      margin-bottom: 1rem;
      width:max-content;
      margin-left: auto;
    }
    .header-annonce .button {
      margin-left: 0;
      margin-bottom: 1rem;
    }
    .buttons.is-right {
      margin-top: 1rem;
      justify-content: center
    }

  }
  @media screen and (min-width:1050px) and (max-width:1920px) {

  }
  @media only screen  and (min-width : 1921px) and (max-width:3000px) {

  }

</style>
